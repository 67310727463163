<template>
  <div>
    <a-modal
      :dialog-style="{ top: '10px' }"
      :title="
        $t('appreciation.adminAppreciation') +
        ': ' +
        selectedStudentRecord.fullName
      "
      :visible="visibleAppreciationModal"
      width="90%"
      height="90%"
      @cancel="() => (visibleAppreciationModal = false)"
      @ok="() => (visibleAppreciationModal = false)"
    >
      <div style="width: 100%; height: 70vh">
        <div class="row" style="height: 100%">
          <div class="col-7">
            <div
              v-if="loadingPdfPreview"
              style="
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <a-col :span="16" :offset="16">
                <a-spin size="large" />
              </a-col>
            </div>
            <iframe
              v-else
              :src="pdfBlobUrl"
              style="background-color: #eee; height: 100%; width: 100%"
            >
            </iframe>
          </div>
          <div class="col-1" style="flex: 0 0 1.333333%">
            <a-divider type="vertical" style="height: 100%" />
          </div>
          <div class="col-4">
            <a-divider>ملاحظات المدّرس(ة) حول السّلوك و المواظبة</a-divider>
            <a-textarea
              :maxLength="140"
              :disabled="loadingPdfPreview"
              v-model="currentAppreciation"
              placeholder="ملاحظات المدّرس(ة) حول السّلوك و المواظبة"
              :auto-size="{ minRows: 4, maxRows: 6 }"
            />
            <div class="row mt-3">
              <div class="col m-auto test-center">
                <a-button
                  :loading="loadingDelete"
                  :disabled="loadingDelete"
                  @click="
                    () => {
                      deleteStudentAppreciation(selectedStudentRecord._id);
                    }
                  "
                  icon="delete"
                  type="danger"
                  style="width: 100%"
                  >{{ $t("action.supprimer") }}</a-button
                >
              </div>
              <div class="col m-auto test-center">
                <a-button
                  icon="save"
                  type="primary"
                  :loading="loadingSave"
                  :disabled="loadingSave"
                  @click="
                    () => {
                      saveStudentAppreciation(selectedStudentRecord._id);
                    }
                  "
                  style="width: 100%"
                  >{{ $t("action.enregistrer") }}</a-button
                >
              </div>
              <div class="col m-auto test-center">
                <a-button
                  :loading="loadingPdfPreview"
                  icon="eye"
                  style="width: 100%"
                  @click="() => previewBulletin(selectedStudentRecord, true)"
                  >{{ $t("action.preview") }}</a-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </a-modal>
    <div class="card">
      <div class="card-body">
        <div class="row success">
          <div class="col-xl-8">
            <h5 style="color: blue; margin-bottom: 30px">
              <span style="margin-right: 30px">{{
                $t("appreciation.adminAppreciation")
              }}</span>
            </h5>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-4">
            <label>{{ $t("bulletin.listeClasses") }}</label>
            <a-select
              show-search
              :placeholder="$t('emploi.listeClasses')"
              option-filter-prop="children"
              :filter-option="filterOption"
              style="width: 150px; margin-left: 10px"
              @change="
                (val) => {
                  handleClassChange('classe', val);
                }
              "
            >
              <a-select-option value="all">---</a-select-option>
              <a-select-option
                v-for="classe in classes"
                :key="classe._id"
                :value="classe._id"
                >{{ classe.name }}</a-select-option
              >
            </a-select>
          </div>

          <div class="col-xl-4">
            <label style="margin-right: 5px">{{
              this.$t("emploi.trimestre")
            }}</label>
            <a-select
              :placeholder="$t('paiement.selectionnerMois')"
              style="width: 100px; margin-left: 15px; margin-right: 20px"
              @change="
                (val) => {
                  this.selectedTrimester = val;
                  this.updateBulletins();
                  this.updateAdminAppreciations();
                }
              "
              default-value="1"
            >
              <a-select-option
                v-for="trimester in ['1', '2', '3']"
                :key="trimester"
                :value="trimester"
                >{{ trimester }}</a-select-option
              >
            </a-select>
          </div>
        </div>
        <a-divider></a-divider>
        <div class="row">
          <div class="col-xl-12">
            <div class="card" v-if="selectedClasse">
              <div class="data-table">
                <a-table
                  @change="tableChanged"
                  :loading="tableLoading"
                  :pagination="true"
                  :data-source="activeData"
                  :columns="columns"
                  :rowClassName="() => 'editable-row'"
                  :customRow="
                    (record) => {
                      return {
                        on: {
                          click: (event) => {
                            this.onStudentClick(record);
                          },
                        },
                      };
                    }
                  "
                >
                  <template slot="moyenne" slot-scope="text, record">
                    {{ getStudentMoyenne(record._id) }}
                  </template>

                  <template slot="appreciation" slot-scope="text, record">
                    {{ getStudentAppreciation(record._id) }}
                  </template>

                  <template slot="name" slot-scope="text">
                    <span class="arabic-font">{{ text }}</span>
                  </template>

                  <div
                    slot="filterDropdown"
                    slot-scope="{
                      setSelectedKeys,
                      selectedKeys,
                      confirm,
                      clearFilters,
                      column,
                    }"
                    style="padding: 8px"
                  >
                    <a-input
                      v-ant-ref="(c) => (searchInput = c)"
                      :placeholder="`${$t('personnel.chercher')} ${
                        column.title
                      }`"
                      :value="selectedKeys[0]"
                      style="width: 188px; margin-bottom: 8px; display: block"
                      @change="
                        (e) =>
                          setSelectedKeys(
                            e.target.value ? [e.target.value] : []
                          )
                      "
                      @pressEnter="
                        () =>
                          handleSearch(selectedKeys, confirm, column.dataIndex)
                      "
                    />
                    <a-button
                      type="primary"
                      icon="search"
                      size="small"
                      style="width: 90px; margin-right: 8px"
                      @click="
                        () =>
                          handleSearch(selectedKeys, confirm, column.dataIndex)
                      "
                      >{{ $t("paiement.chercher") }}</a-button
                    >
                    <a-button
                      size="small"
                      style="width: 90px"
                      @click="() => handleReset(clearFilters)"
                      >{{ $t("action.reinitialiser") }}</a-button
                    >
                  </div>
                  <a-icon
                    slot="filterIcon"
                    slot-scope="filtered"
                    type="search"
                    :style="{ color: filtered ? '#108ee9' : undefined }"
                  />
                  <template
                    slot="customRender"
                    slot-scope="text, record, index, column"
                  >
                    <span
                      v-if="searchText && searchedColumn === column.dataIndex"
                    >
                      <template
                        v-for="(fragment, i) in text
                          .toString()
                          .split(
                            new RegExp(
                              `(?<=${searchText})|(?=${searchText})`,
                              'i'
                            )
                          )"
                      >
                        <mark
                          v-if="
                            fragment.toLowerCase() === searchText.toLowerCase()
                          "
                          :key="i"
                          class="highlight"
                          >{{ fragment }}</mark
                        >
                        <template v-else>{{ fragment }}</template>
                      </template>
                    </span>
                    <template v-else>{{ text }}</template>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/*eslint-disable */
//ANCHOR class/month filter to this view: DONE

import apiClient from "@/services/axios";

import { mapState } from "vuex";
import JsPDF from "jspdf";
import "jspdf-autotable";
import { notification, Modal } from "ant-design-vue";
import moment from "moment";

export default {
  computed: {
    ...mapState(["settings", "user"]),
  },
  components: {},
  async created() {
    this.tableLoading = true;

    await apiClient
      .post("/classrooms/filter", {
        query: {
          status: "active",
        },
        aggregation: {
          $project: {
            _id: 1,
            name: 1,
            level: 1,
            bulletin: 1,
          },
        },
      })
      .then((res) => (this.classes = res.data))
      .catch((e) => {
        console.log(e);
      });

    this.filtredTable = this.activeData;

    this.schoolDetails = this.user.building.filter(
      (el) => el.dbName == this.settings.activeBuilding
    )[0];
    let { start, end } = this.settings.schoolarYears.find(
      (el) => el._id == this.settings.activeSchoolarYear
    );
    console.log(start, end);

    start = new Date(start).getFullYear();
    end = new Date(end).getFullYear();

    //console.log(this.schoolarYearName);
    this.schoolarYearName = end + " / " + start;
    console.log(this.schoolarYearName);
    console.log("schoolDetails :", this.schoolDetails);
    this.schoolType = this.schoolDetails.type;
    if (!this.schoolType)
      notification.warning({
        message: this.$t("bulletin.typeIntrouvable"),
        description: this.$t("bulletin.saisirSchoolType"),
      });
  },
  data() {
    return {
      pdfBlobUrl: "",
      certifParams: [],
      loadingBulletin: false,
      printLoading: false,
      visibleClassnameList: false,
      visibleStudentList: false,
      visibleBulletinDate: false,
      visibleFinalBulletin: false,
      visibleAllFinalBulletin: false,
      bulletinDate: new Date(),
      moyenne: "--",
      maxMoyenne: "--",
      minMoyenne: "--",
      bulletin: { maxMoyenne: "--", minMoyenne: "--", moyenne: "--" },
      schoolType: null,
      schoolDetails: {},
      filtredTable: [],
      selectedStudent: this.$t("menu.liste"),
      selectedStudentName: "",
      studentLoading: false,
      selectedTrimester: "1",
      selectedClasse: null,
      tableLoading: false,
      loadingPdfPreview: false,
      activeData: [],
      appreciations: [],
      loadingSave: false,
      loadingDelete: false,
      currentAppreciation: "",
      visibleAppreciationModal: false,
      selectedStudentRecord: {},
      filters: {
        classe: false,
        classe_val: null,
        month: false,
        month_val: null,
      },
      rowData: [],
      data1: null,
      classes: [],
      editable: false,
      searchText: "",
      searchInput: null,
      searchedColumn: "",
      columns: [
        {
          title: this.$t("bulletin.nom"),
          dataIndex: "fullName",
          key: "fullName",
          scopedSlots: {
            customRender: "fullName",
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          onFilter: (value, record) =>
            record.name.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          },
        },
        {
          title: this.$t("bulletin.moyenne"),
          dataIndex: "moyenne",
          scopedSlots: { customRender: "moyenne" },
          sorter: (a, b) =>
            Number(this.getStudentMoyenne(a._id)) -
            Number(this.getStudentMoyenne(b._id)),
        },
        {
          title: this.$t("appreciation.appreciation"),
          dataIndex: "appreciation",
          scopedSlots: { customRender: "appreciation" },
        },
      ],
      listModules: [],
      selectedLevel: null,
      selectedClassName: "",
      bulletinData: [],
    };
  },
  methods: {
    moment,
    async onStudentClick(record) {
      this.selectedStudentRecord = record;
      this.visibleAppreciationModal = true;
      this.loadingPdfPreview = true;
      apiClient
        .get(
          "/bulletin/" +
            this.selectedStudentRecord._id +
            "/" +
            this.selectedTrimester
        )
        .then(({ data }) => {
          this.bulletin = data;
          this.currentAppreciation = this.bulletin.adminAppreciation || "";
          this.previewBulletin(record);
        });
    },
    getStudentMoyenne(studentID) {
      if (this.bulletinData.length == 0) return "--";
      const student = this.bulletinData.students.find(
        (el) => el.studentID == studentID
      );
      return student ? student.moyenne || "--" : "--";
    },
    getStudentAppreciation(studentID) {
      if (this.appreciations.length == 0) return "--";
      const app = this.appreciations.find(
        (el) => String(el.student) == studentID
      );
      return app ? app.appreciation : "--";
    },

    async updateAdminAppreciations() {
      await apiClient
        .post("/adminAppreciation/filter", {
          query: {
            schoolarYear: this.settings.activeSchoolarYear,
            trimester: this.selectedTrimester,
            classroom: this.selectedClasse,
          },
        })
        .then(({ data }) => {
          this.appreciations = [];
          data.forEach((el) => {
            this.appreciations.push(el);
          });
        });
    },

    async saveStudentAppreciation(studentID) {
      this.loadingSave = true;
      const app = this.appreciations.find(
        (el) => String(el.student) == studentID
      );

      if (app) {
        await apiClient
          .patch("/adminAppreciation/" + app._id, {
            appreciation: this.currentAppreciation,
          })
          .then((res) => {
            app.appreciation = this.currentAppreciation;
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        await apiClient
          .put("/adminAppreciation", {
            appreciation: this.currentAppreciation,
            student: studentID,
            trimester: this.selectedTrimester,
            schoolarYear: this.settings.activeSchoolarYear,
            classroom: this.selectedClasse,
          })
          .then((res) => {
            this.appreciations.push(res.data);
          });
      }

      this.$message.success(this.$t("appreciation.saved"));

      this.previewBulletin(this.selectedStudentRecord, true);
      this.loadingSave = false;
    },

    async deleteStudentAppreciation(studentID) {
      this.loadingDelete = true;
      const app = this.appreciations.find(
        (el) => String(el.student) == studentID
      );

      if (app)
        await apiClient.delete("/adminAppreciation/" + app._id).then((res) => {
          this.appreciations = this.appreciations.filter(
            (el) => el._id != app._id
          );
        });

      this.$message.success(this.$t("appreciation.deleted"));

      this.currentAppreciation = "";

      this.previewBulletin(this.selectedStudentRecord, true);
      this.loadingDelete = false;
    },

    async updateCertifParams() {
      await apiClient
        .get("/certification/peda")
        .then((res) => {
          this.certifParams = res.data;
          console.log(this.certifParams);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getCertificationNameByMoyenne(moyenne) {
      for (const cert of this.certifParams)
        if (
          Number(moyenne) >= Number(cert.min) &&
          Number(moyenne) <= Number(cert.max)
        )
          return cert.name;
      return "";
    },

    async updateBulletins() {
      this.tableLoading = true;
      await apiClient
        .get(
          "/bulletin/all/" + this.selectedClasse + "/" + this.selectedTrimester
        )
        .then(({ data }) => {
          this.bulletinData = data;
        })
        .finally(() => (this.tableLoading = false));
    },

    tableChanged(pagination, filters, sorter, extra) {
      this.filtredTable = extra.currentDataSource;
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },

    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },

    async handleClassChange(on, value) {
      this.selectedStudent = null;
      this.selectedStudentName = "";
      this.studentLoading = true;
      this.tableLoading = true;
      this.selectedClasse = value;
      this.selectedClassName = this.classes.find(
        (item) => item._id === value
      ).name;
      this.selectedLevel = this.classes.find(
        (item) => item._id === value
      ).level;
      let match = {};
      match[`schoolarYearsHistory.${this.settings.activeSchoolarYear}`] = value;
      await apiClient
        .post("/students/filter", {
          query: { status: "active" },
          aggregation: [
            {
              $match: match,
            },
            {
              $set: {
                classRoom: {
                  $convert: {
                    input: `$schoolarYearsHistory.${this.settings.activeSchoolarYear}`,
                    to: "objectId",
                    onError: null,
                    onNull: null,
                  },
                },
              },
            },
            {
              $lookup: {
                from: "classrooms",
                localField: "classRoom",
                foreignField: "_id",
                as: "classroom",
              },
            },
            {
              $project: {
                _id: 1,
                firstName: 1,
                lastName: 1,
                gender: 1,
                classroom: {
                  _id: 1,
                  name: 1,
                },
              },
            },
            {
              $sort: {
                firstName: 1,
              },
            },
          ],
        })
        .then(({ data }) => {
          this.rowData = [];
          data.map((elem) => {
            try {
              this.rowData.push({
                _id: elem._id,
                key: elem._id,
                fullName: elem.firstName + " " + elem.lastName,
                classroom: elem.classroom[0].name,
                classroom_id: elem.classroom[0]._id,
                gender: elem.gender,
              });
            } catch {}
          });
          this.activeData = this.rowData;
          this.filtredTable = this.activeData;
          this.studentLoading = false;
        });

      await apiClient
        .get(
          "/bulletin/all/" + this.selectedClasse + "/" + this.selectedTrimester
        )
        .then(({ data }) => {
          this.bulletinData = data;
        });

      this.tableLoading = false;

      this.updateAdminAppreciations();
    },

    roundRect(ctx, x, y, width, height, radius, fill, stroke) {
      const cornerRadius = {
        upperLeft: 0,
        upperRight: 0,
        lowerLeft: 0,
        lowerRight: 0,
      };
      if (typeof stroke == "undefined") {
        stroke = true;
      }
      if (typeof radius === "object") {
        for (const side in radius) {
          cornerRadius[side] = radius[side];
        }
      }

      ctx.lineWidth = 1;
      ctx.strokeStyle = "rgb(70, 187, 239)";

      ctx.beginPath();
      ctx.moveTo(x + cornerRadius.upperLeft, y);
      ctx.lineTo(x + width - cornerRadius.upperRight, y);
      ctx.quadraticCurveTo(
        x + width,
        y,
        x + width,
        y + cornerRadius.upperRight
      );
      ctx.lineTo(x + width, y + height - cornerRadius.lowerRight);
      ctx.quadraticCurveTo(
        x + width,
        y + height,
        x + width - cornerRadius.lowerRight,
        y + height
      );
      ctx.lineTo(x + cornerRadius.lowerLeft, y + height);
      ctx.quadraticCurveTo(
        x,
        y + height,
        x,
        y + height - cornerRadius.lowerLeft
      );
      ctx.lineTo(x, y + cornerRadius.upperLeft);
      ctx.quadraticCurveTo(x, y, x + cornerRadius.upperLeft, y);
      ctx.closePath();
      if (stroke) {
        ctx.stroke();
      }
      if (fill) {
        ctx.fill();
      }
    },

    drawModuleHeader(doc, ctx, startY, title, style = 1, isLangModule = false) {
      // module title

      let x = style == 0 ? 80 : 65;
      let y = 63 + startY;
      let width = style == 0 ? 110 : 125;
      doc.setFillColor(64, 187, 239);
      this.roundRect(ctx, x, y, width, 8, { upperRight: 8 }, true, true);
      doc.setFillColor(0xff, 0xff, 0xff);
      doc.setDrawColor(0xff, 0xff, 0xff);
      doc.rect(x, y + 20, width + 10, 10, "FD");

      // module header shapes
      x = 158;
      y = 72.6 + startY;
      width = 45;
      doc.setFillColor(64, 187, 239);
      this.roundRect(ctx, x, y, width, 7.5, { upperRight: 7.5 }, true, true);
      // doc.setFillColor(0xff, 0xff, 0xff);
      // doc.setDrawColor(0xff, 0xff, 0xff);
      // doc.rect(x, y + 9.9, width + 10, 10, "FD");

      x = 148;
      y = 72.6 + startY;
      width = style == 0 ? 26 : 18;
      if (isLangModule) {
        width -= 4;
        x += 5;
      }
      doc.setFillColor(224, 237, 243);
      //العدد / 20
      this.roundRect(ctx, x, y, width, 7.4, { upperRight: 7.4 }, true, true);
      // doc.setFillColor(0xff, 0xff, 0xff);
      // doc.setDrawColor(0xff, 0xff, 0xff);
      // doc.rect(x, y + 9.9, width + 10, 10, "FD");

      doc.setLineWidth(0.4);
      doc.setFillColor(224, 237, 243);
      doc.setDrawColor(64, 187, 239);
      // معدل
      if (isLangModule) {
        doc.rect(137, 72.3 + startY, 15, 8, "FD");
      }

      //معدل المجال;
      if (style == 1) {
        x = 0;
        if (isLangModule) {
          x = 10;
        }
        doc.rect(134 - x, 72.3 + startY, 15, 8, "FD");
      }
      //توصيّات المدّرس
      x = 0;
      width = style == 0 ? 46 : 38;
      if (isLangModule) {
        width -= 2;
        x = 8;
      }
      doc.rect(style == 0 ? 111 : 96 - x, 72.3 + startY, width, 8, "FD");

      // max / min
      width = 15;
      x = 0;
      if (isLangModule) {
        width = 11;
        x = 4;
      }
      doc.rect(style == 0 ? 80 : 65, 72.3 + startY, width, 8, "FD");
      doc.rect((style == 0 ? 95 : 80) - x, 72.3 + startY, width, 8, "FD");

      doc.line(155, 80.3 + startY, 202.5, 80.3 + startY);

      // header module texts
      if (/[a-zA-Z]/.test(title)) doc.setFont("Amiri", "Bold");
      else doc.setFont("Noto Sans Arabic", "Bold");
      doc.setFontSize(13);
      let text = title;
      doc.setTextColor(0xff, 0xff, 0xff);
      width = doc.getTextWidth(text);
      doc.text(text, 138 - width / 2 - (style == 0 ? 0 : 15), 69 + startY);

      doc.setFont("Amiri", "Bold");
      text = "المادة";
      width = doc.getTextWidth(text);
      doc.text(text, 179, 78 + startY);

      text = "العدد / 20";
      doc.setFontSize(9);
      x = style == 0 ? 158 : 151;
      if (isLangModule) {
        doc.setFontSize(7);
        x += 4;
      }

      doc.setTextColor(62, 187, 239);
      width = doc.getTextWidth(text);
      doc.text(text, x, 78 + startY);

      if (isLangModule) {
        text = "معدل";
        doc.setFontSize(9);
        doc.setTextColor(64, 187, 239);
        width = doc.getTextWidth(text);
        doc.text(text, 146, 77.5 + startY, "center");
      }

      if (style == 1) {
        text = "معدل\nالمجال";
        x = 0;
        if (isLangModule) x = 11;
        doc.setFontSize(9);
        doc.setTextColor(64, 187, 239);
        width = doc.getTextWidth(text);
        doc.text(text, 142 - x, 75.2 + startY, "center");
      }

      text = "توصيّات المدّرس";
      x = 0;
      if (isLangModule) x = 8;
      doc.setFontSize(11);
      doc.setTextColor(64, 187, 239);
      width = doc.getTextWidth(text);
      doc.text(text, style == 0 ? 125 : 104 - x, 77.5 + startY);

      doc.setFontSize(8);
      x = 0;
      if (isLangModule) {
        doc.setFontSize(7);
        x = 5.5;
      }
      text = "أعلى\nعدد بالقسم";
      doc.setTextColor(64, 187, 239);
      width = doc.getTextWidth(text);
      doc.text(text, style == 0 ? 103 : 88 - x, 75 + startY, "center");

      if (isLangModule) x = 1;

      text = "أدنى\nعدد بالقسم";
      doc.setTextColor(64, 187, 239);
      width = doc.getTextWidth(text);
      doc.text(text, style == 0 ? 87 : 72 - x, 75 + startY, "center");
    },

    drawModuleFooter(doc, ctx, startY, avg) {
      let x = 158;
      let y = startY;
      let width = 35.5;
      doc.setFillColor(16, 169, 229);
      doc.setDrawColor(16, 169, 229);
      doc.rect(x, y, width + 10, 8.5, "FD");

      x = 111;
      y = startY + 0.4;
      width = 45.5;
      doc.setFillColor(255, 255, 255);

      this.roundRect(ctx, x, y, width, 7.8, { lowerLeft: 7.8 }, true, true);

      // footer text (moyenne)
      doc.setFont("Noto Sans Arabic", "Bold");
      doc.setFontSize(13);
      let text = "معدل المجال";
      doc.setTextColor(0xff, 0xff, 0xff);
      doc.text(text, 169, startY + 5.5);

      doc.setFont("Amiri", "Bold");
      doc.setFontSize(14);
      text = String(avg);
      doc.setTextColor(67, 62, 63);
      width = doc.getTextWidth(text);
      doc.text(text, 134.75 - width / 2, startY + 5.5);
    },

    async drawTrimesterMoyenneAndCards(
      doc,
      ctx,
      startY,
      moy,
      max,
      min,
      style = 1,
      stampImage,
      isPreview,
      appreciation = ""
    ) {
      const value = style == 1 ? 5 : 0;

      doc.setFillColor(46, 142, 178);
      doc.setDrawColor(46, 142, 178);
      this.roundRect(
        ctx,
        45 - value * 2,
        82.2 + startY,
        33.5 - value,
        8.2,
        { upperRight: 7.8 },
        true,
        false
      );

      doc.setFont("Noto Sans Arabic", "Bold");
      doc.setFontSize(11);
      let text = "معدل الثلاثي";
      doc.setTextColor(0xff, 0xff, 0xff);
      doc.text(text, 51 - (value * 2 + value / 2), 87.5 + startY);

      doc.setLineWidth(0.2);
      doc.setFillColor(224, 237, 243);
      doc.setDrawColor(64, 187, 239);

      this.roundRect(
        ctx,
        6,
        82.2 + startY,
        38 - value * 2,
        7.8,
        { upperRight: 7.8 },
        true,
        true
      );
      this.roundRect(
        ctx,
        6,
        82.2 + startY,
        19 - value,
        7.8,
        { upperRight: 7.8 },
        true,
        true
      );

      doc.setFont("Amiri", "Bold");
      doc.setFontSize(7);
      doc.setTextColor(64, 187, 239);
      doc.text(
        "أعلى\nمعدل بالقسم",
        38 - (value + value / 2),
        85.5 + startY,
        "right"
      );
      doc.text("أدنى\nمعدل بالقسم", 19.2 - value / 2, 85.5 + startY, "right");

      // moyenne rect
      doc.setFillColor(224, 237, 243);
      doc.setDrawColor(64, 187, 239);
      doc.setLineWidth(0.15);
      doc.rect(45 - value * 2, 91.5 + startY, 33.5 - value, 16.4, "FD");

      doc.setFillColor(225, 225, 226);
      doc.rect(6, 91.5 + startY, 19 - value, 16.4, "FD");
      doc.rect(25 - value, 91.5 + startY, 19 - value, 16.4, "FD");

      // moyenne text
      doc.setTextColor(67, 62, 63);
      doc.setFontSize(16);
      text = String(moy);
      let width = doc.getTextWidth(text);
      doc.text(text, 63 - width / 2 - (value * 2 + value / 2), 100.5 + startY);

      doc.setFontSize(13);
      text = String(max);
      width = doc.getTextWidth(text);
      doc.text(text, 34.75 - width / 2 - (value + value / 2), 100.5 + startY);

      text = String(min);
      width = doc.getTextWidth(text);
      doc.text(text, 15.25 - width / 2 - value / 2, 100.5 + startY);

      doc.setFillColor(224, 237, 243);
      doc.setDrawColor(64, 187, 239);

      doc.rect(6, 115 + startY, 72.5 - value * 3, 30);
      doc.rect(8, 111 + startY, 67.5 - value * 3, 8, "FD");

      doc.rect(6, 153 + startY, 72.5 - value * 3, 30);
      doc.rect(40.75 - value * 3, 149 + startY, 36.75, 8, "FD");

      doc.rect(6, 190 + startY, 72.5 - value * 3, 55);
      doc.rect(40.75 - value * 3, 186 + startY, 36.75, 8, "FD");

      doc.rect(6, 252 + startY, 72.5 - value * 3, 30);
      doc.rect(40.75 - value * 3, 248 + startY, 36.75, 8, "FD");

      doc.setFontSize(11 - value / 2);
      doc.setTextColor(64, 187, 239);
      text = "ملاحظات المدّرس)ة( حول السّلوك و المواظبة";
      doc.text(text, 12, 116 + startY);

      doc.setTextColor(67, 62, 63);
      doc.setFontSize(10);
      const tt = this.currentAppreciation;
      doc.text(String(tt), 35, 125 + startY, {
        maxWidth: /[a-zA-Z]/.test(tt) ? 50 : 60,
        align: "center",
      });

      doc.setFontSize(13);
      text = "الشّهادة";
      doc.text(text, 54 - value * 3, 154 + startY);
      doc.setTextColor(67, 62, 63);
      doc.setFontSize(17);

      doc.text(
        this.getCertificationNameByMoyenne(moy),
        35 - value * 3,
        170 + startY
      );
      doc.setFontSize(13);

      doc.setTextColor(64, 187, 239);

      text = "مدير)ة( المدرسة";
      doc.text(text, 45 - value * 3, 191 + startY);

      doc.setTextColor(67, 62, 63);
      doc.text(
        this.schoolDetails.headMaster +
          "\n" +
          moment(this.bulletinDate).format("YYYY/MM/DD"),
        30 - value * 3,
        202 + startY
      );

      //stamp

      if (stampImage && stampImage.src) {
        try {
          doc.addImage(stampImage, "PNG", 10, 190, 30, 30);
        } catch (e) {}
      }
      //end stamp
      doc.setTextColor(64, 187, 239);
      doc.setFontSize(16);

      text = "إمضاء الولي";
      doc.text(text, 48 - value * 3, 253 + startY);
    },

    waitLoadingImage(image) {
      return new Promise((res, rej) => {
        image.onload = (t) => {
          res();
        };
        image.onerror = (e) => {
          rej();
        };
      });
    },

    //generate bulletin pdf
    async previewBulletin(student, isPreview = false) {
      this.loadingPdfPreview = true;

      const style = this.selectedLevel > 2 ? 1 : 0;

      const record = student;

      const doc = new JsPDF();
      const ctx = doc.context2d;
      const pdf_width = doc.internal.pageSize.width;
      const pdf_height = doc.internal.pageSize.height;
      let width = 0;
      let width2 = 0;

      // background header
      const img = new Image();
      img.src = "./resources/images/bg1.png";
      doc.addImage(img, "png", 0, 0, pdf_width, 40 - (style == 1 ? 12 : 0));

      // Header texts
      doc.setFont("PT Bold Heading", "Bold");
      doc.setFontSize(13);
      let text = "المندوبيّة الجهويّة للتربيّة";
      doc.setTextColor(99, 87, 91);
      width = doc.getTextWidth(text);
      doc.text(text, pdf_width - width - 15, 12);
      console.log(this.schoolDetails);
      text = this.schoolDetails.delegeRegional
        ? "بـ" + this.schoolDetails.delegeRegional
        : ".....................................بـ";
      width = doc.getTextWidth(text);
      doc.text(text, pdf_width - width - 15, 22);

      doc.setFont("Amiri", "normal");

      doc.setTextColor(67, 62, 63);
      doc.setFontSize(12);
      text = this.schoolDetails.ArabicName
        ? " المدرسة الابتدائيّة " + this.schoolDetails.ArabicName
        : "................................................... : المدرسة الابتدائيّة";
      width = doc.getTextWidth(text);
      doc.text(text, 105 - width - 15, 12);
      text = ` ${this.schoolarYearName}  : السنة الدراسيّة`;
      width = doc.getTextWidth(text);
      doc.text(text, 105 - width - 15, 20);

      // ending header line
      doc.setDrawColor(52, 172, 215);
      doc.setLineWidth(0.8);
      doc.line(
        0,
        40 - (style == 1 ? 12 : 0),
        pdf_width,
        40 - (style == 1 ? 12 : 0)
      );

      // trimester rounded rect title
      doc.setDrawColor(52, 172, 215);
      doc.setLineWidth(0.4);
      doc.setFillColor(255, 255, 255);
      doc.roundedRect(
        pdf_width / 2 - 25,
        34 - (style == 1 ? 12 : 0),
        50,
        12,
        4,
        4,
        "FD"
      );

      // trimester text
      doc.setFontSize(16);
      doc.setFont("Amiri", "normal");
      doc.setTextColor(52, 172, 215);
      let title = "";
      switch (this.selectedTrimester) {
        case "1":
          title = "الثلاثي الأول";
          break;
        case "2":
          title = "الثلاثي الثاني";
          break;
        case "3":
          title = "الثلاثي الثالث";
          break;
      }
      doc.setFont("Noto Sans Arabic", "Bold");
      width = doc.getTextWidth(title);
      doc.text(title, pdf_width / 2 - width / 2, 41.2 - (style == 1 ? 12 : 0));

      const className = this.bulletin.classroomName;

      let studentLabel =
        record.gender === "male" ? " : التلميذ" : " : التلميذة";

      // student name
      doc.setFont("Amiri", "normal");
      doc.setFontSize(12);
      doc.setTextColor(99, 87, 91);
      text = studentLabel;
      width = doc.getTextWidth(text);
      doc.text(text, pdf_width - width - 12, 53 - (style == 1 ? 12.5 : 0));

      text = this.bulletin.fullName;
      doc.setFont("Amiri", "Bold");
      doc.setFontSize(13);
      width2 = doc.getTextWidth(text);
      doc.text(
        text,
        pdf_width - width - width2 - 12,
        53 - (style == 1 ? 12.5 : 0)
      );
      doc.setFont("Amiri", "normal");
      doc.setFontSize(12);
      // student classroom
      text = " : القسم";
      doc.setTextColor(99, 87, 91);
      width = doc.getTextWidth(text);
      doc.text(text, pdf_width - width - 100, 53 - (style == 1 ? 12.5 : 0));
      text = className;
      width2 = doc.getTextWidth(text);
      doc.text(
        text,
        pdf_width - width - width2 - 100,
        53 - (style == 1 ? 12.5 : 0)
      );

      // number of students in classroom
      /* text = " : عدد التلاميذ المرسّمين";
        doc.setTextColor(99, 87, 91);
        width = doc.getTextWidth(text);
        doc.text(text, pdf_width - width - 156, 53 - (style == 1 ? 12.5 : 0));
        text = String(this.bulletin.students);
        width2 = doc.getTextWidth(text);
        doc.text(
          text,
          pdf_width - width - width2 - 156,
          53 - (style == 1 ? 12.5 : 0)
        );*/

      const cellToDelete = {};
      let topMargin = -4 - (style == 1 ? 13 : 0);

      let tableSize = 9;
      switch (this.selectedLevel) {
        case 1:
          tableSize = 12;
          break;
        case 2:
          tableSize = 12;
          break;
        case 3:
          tableSize = 11;
          break;
        case 4:
          tableSize = 10.5;
          break;
        default:
          break;
      }

      this.bulletin.modules.forEach((m) => {
        let arr = [];
        let array = [];

        this.drawModuleHeader(doc, ctx, topMargin, m.name, style, m.submodules);
        topMargin += 17.2;

        m.subjects.forEach((c) => {
          let subFormattedName = c.name;
          if (c.name.length > 20 && c.name.indexOf(" و ") != -1) {
            let indexOfFirst = c.name.indexOf(" و ");
            subFormattedName =
              c.name.slice(0, indexOfFirst) + "\n" + c.name.slice(indexOfFirst);
          }
          arr[m.submodules ? 7 : 6] = {
            content: subFormattedName,
            styles: {
              halign: "right",
              fillColor: "#40bbef",
              fontSize: tableSize,
              textColor: "#ffffff",
              lineColor: [229, 229, 229],
            },
          };

          arr[m.submodules ? 6 : 5] = {
            content: c.mark,
            styles: {
              halign: "center",
              valign: "center",
              fontSize: 10,
              lineColor: [52, 172, 215],
            },
          };

          arr[4] = cellToDelete;
          if (style == 1) arr[3] = cellToDelete;
          arr[2] = cellToDelete;

          //best mark
          arr[1] = {
            content: c.max,
            styles: {
              halign: "center",
              valign: "center",
              fontSize: m.submodules ? 9 : 10,
              lineColor: [52, 172, 215],
            },
          };

          //lowest mark
          arr[0] = {
            content: c.min,
            styles: {
              halign: "center",
              valign: "center",
              fontSize: m.submodules ? 9 : 10,
              lineColor: [52, 172, 215],
            },
          };

          array.push(arr);
          arr = [];
        });

        array[0][2] = {
          content: "",
          rowSpan: array.length,
          styles: {
            lineColor: [52, 172, 215],
          },
        };
        if (!m.submodules) {
          array[0][style == 1 ? 3 : 4] = {
            content: m.appreciation.trim(),
            rowSpan: array.length,
            styles: {
              halign: "center",
              valign: "top",
              fontSize: m.appreciation.length > 15 ? 10 : 12,
              lineColor: [52, 172, 215],
            },
          };
        } else {
          array[0][3] = {
            content: m.submodules[0].appreciation.trim(),
            rowSpan: 3,
            styles: {
              halign: "center",
              valign: "top",
              fontSize: 10,
              lineColor: [52, 172, 215],
            },
          };
          array[3][3] = {
            content: m.submodules[1].appreciation.trim(),
            rowSpan: 2,
            styles: {
              halign: "center",
              valign: "top",
              fontSize: 10,
              lineColor: [52, 172, 215],
            },
          };
        }

        if (style == 1)
          array[0][4] = {
            content: m.moyenne,
            rowSpan: array.length,
            styles: {
              halign: "center",
              fontSize: 12,
              valign: style ? "center" : "top",
              lineColor: [52, 172, 215],
            },
          };

        if (m.submodules) {
          array[0][5] = {
            content: m.submodules[0].moyenne,
            rowSpan: 3,
            styles: {
              halign: "center",
              fontSize: 12,
              valign: "center",
              lineColor: [52, 172, 215],
            },
          };
          array[3][5] = {
            content: m.submodules[1].moyenne,
            rowSpan: 2,
            styles: {
              halign: "center",
              fontSize: 12,
              valign: "center",
              lineColor: [52, 172, 215],
            },
          };
        }

        for (let row = 0; row < array.length; row++) {
          array[row] = array[row].filter((cell) => cell !== cellToDelete);
        }

        doc.autoTable({
          startY: 64 + topMargin,
          theme: "grid",
          styles: {
            font: "Amiri",
            halign: "center",
            fontStyle: "Bold",
          },
          cellPadding: 0,
          body: array,
          margin: { left: style == 1 ? 65 : 80, bottom: 0 },
          didDrawPage: function (data) {
            // Reseting top margin. The change will be reflected only after print the first page.
            data.settings.margin.top = 10;
          },

          columnStyles:
            style == 1
              ? m.submodules
                ? {
                    0: { cellWidth: 11 },
                    1: { cellWidth: 11 },
                    2: { cellWidth: 1 },
                    3: { cellWidth: 36 },
                    4: { cellWidth: 15 },
                    5: { cellWidth: 14 },
                    6: { cellWidth: 14 },
                    7: { cellWidth: 37 },
                  }
                : {
                    0: { cellWidth: 15 },
                    1: { cellWidth: 15 },
                    2: { cellWidth: 1 },
                    3: { cellWidth: 38 },
                    4: { cellWidth: 15 },
                    5: { cellWidth: 17 },
                    6: { cellWidth: 38 },
                  }
              : {
                  0: { cellWidth: 15 },
                  1: { cellWidth: 15 },
                  2: { cellWidth: 1 },
                  3: { cellWidth: 46 },
                  4: { cellWidth: 17 },
                  5: { cellWidth: 30 },
                },
          drawRow: function (row, data) {
            row.height = 2;
          },
        });

        if (style == 0)
          this.drawModuleFooter(
            doc,
            ctx,
            doc.lastAutoTable.finalY + 1,
            m.moyenne
          );

        topMargin = doc.lastAutoTable.finalY - 61.4 + (style == 0 ? 11 : 0);
      });

      let cardStartY = 0;
      switch (this.selectedLevel) {
        case 1:
          cardStartY = -15;
          break;
        case 2:
          cardStartY = -15;
          break;
        case 3:
          cardStartY = -22;
          break;
        case 4:
          cardStartY = -22;
          break;
        case 5:
          cardStartY = -22;
          break;
        case 6:
          cardStartY = -22;
          break;
        default:
          break;
      }
      //stamp
      let stamp = this.settings.base_url + "/" + this.schoolDetails.stamp;
      const stampImage = new Image(30);
      if (stamp) stampImage.src = stamp;
      if (stamp) {
        try {
          await this.waitLoadingImage(stampImage);
          //doc.addImage(stampImage, "PNG", 10, 180, 40, 40);
        } catch (e) {}
      }
      //end stamp
      this.drawTrimesterMoyenneAndCards(
        doc,
        ctx,
        cardStartY,
        this.bulletin.moyenne,
        this.bulletin.maxMoyenne,
        this.bulletin.minMoyenne,
        style,
        stampImage,
        isPreview
      );
      this.pdfBlobUrl = doc.output("bloburl");
      this.loadingPdfPreview = false;
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
};
</script>
<style scoped>
.arabic-font {
  font-size: 14px !important;
  font-family: Verdana, Geneva, Tahoma, sans-serif !important;
}

.success .ant-btn-danger {
  background: #8bc34a !important;
  border-color: #8bc34a !important;
}

::v-deep .editable-row:hover {
  cursor: pointer;
}
</style>
